@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

html,
body {
  /* font-family: "Georgia", serif; */
  font-family: "Inter", "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
}
#root,
.app {
  height: 100%;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
