.ql-editor .link-preview,
.preview-content .link-preview {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px 0;
}

.link-preview-content {
  display: flex;
  text-decoration: none;
  color: inherit;
}

.link-preview-text {
  flex: 1;
  padding: 12px;
  overflow: hidden;
}

.link-preview-title {
  font-weight: bold;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-preview-description {
  font-size: 0.9em;
  color: #636363;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.6em;
  max-height: 3.6em;
}

.link-preview-domain {
  font-size: 0.8em;
  color: #757575;
}

.link-preview-image-container {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.link-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Additional styles for the Quill editor */
.ql-editor {
  min-height: 200px;
  font-size: 16px;
  line-height: 1.5;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.ql-editor p {
  margin-bottom: 10px;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.ql-editor code,
.ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 4px;
  font-family: monospace;
}

.ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

/* Toolbar styles */
.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
