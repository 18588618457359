@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

/* customQuillStyles.css */
.ql-editor {
  font-family: "Inter", "Helvetica", sans-serif;
  line-height: 2;
}

/* Custom styles for ReactQuill toolbar icons */
.ql-toolbar button {
  width: 24px !important;
  height: 24px !important;
}

/* Custom styles for smaller screens */
@media (max-width: 600px) {
  .ql-toolbar button {
    width: 32px !important;
    height: 32px !important;
  }

  /* Ensure the alignment pickers and color pickers are also resized */
  .ql-toolbar .ql-picker {
    width: 32px !important;
    height: 32px !important;
  }

  .ql-toolbar .ql-picker-options {
    min-width: 32px !important;
  }

  .ql-toolbar .ql-header {
    width: 90px !important;
    height: 24px !important;
  }
}

/* Custom styles for header dropdown */
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Title";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Subtitle";
}

.ql-snow .ql-picker.ql-header .ql-picker-label:not([data-value])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item:not([data-value])::before {
  content: "Normal";
}

/* Custom styles for headers in the editor */
.ql-editor h3 {
  font-size: 42;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.ql-editor h4 {
  font-size: 36;
  font-weight: bold;
}

.ql-editor p {
  margin: 0;
}
